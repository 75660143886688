import React from "react"
import "./spinner.scss"

// Source for Loading Dots: Matt Literhand, https://codepen.io/mattsince87/pen/uoDtH

export default () => {
  return (
    <div className="spinner-wrapper">
      <div className="preloader">
        <i className="dots">.</i>
        <i className="dots">.</i>
        <i className="dots">.</i>
      </div>
    </div>
  )
}
