import React, { Component } from "react"
import FacebookLogin from "react-facebook-login"
import GoogleLogin from "react-google-login"

import { connect } from "react-redux"
import { setUser, signOut } from "../state/app"

import "../components/auth.scss"
import "../components/layout.scss"

class Auth extends Component {
  responseFacebook = response => {
    if (response.status === "unknown" || response.status === "not_authorized") {
      return
    }
    const { email } = response

    let user = {
      email,
      expires_at: Date.now() + 31556926 / 1000,
    }

    this.props.setUser(user)
  }

  responseGoogle = response => {
    if (response.error) {
      return
    }
    const { email } = response.profileObj

    let user = {
      email,
      expires_at: Date.now() + 31556926 / 1000,
    }

    this.props.setUser(user)
  }

  signOut = () => {
    if (window.FB) {
      window.FB.logout()
    }
    this.props.signOut()
  }

  render() {
    return (
      <div className="content auth-buttons">
        <FacebookLogin
          appId="492108311651070"
          fields="name,email,picture"
          callback={this.responseFacebook}
          isMobile={false}
          autoLoad={false}
          reAuthenticate={true}
          authType="reauthenticate"
          reRequest={true}
        />
        <GoogleLogin
          clientId="418767394256-9v6ap5mfee3ttujp12u73mh1pn46nhee.apps.googleusercontent.com"
          buttonText="LOGIN WITH GOOGLE"
          onSuccess={this.responseGoogle}
          onFailure={this.responseGoogle}
          className="google-button"
          isSignedIn={false}
          prompt="select_account"
        />
      </div>
    )
  }
}

export default connect(
  null,
  { setUser, signOut }
)(Auth)
