import React, { Component } from "react"
import { Element } from "react-scroll"
import { connect } from "react-redux"
import { setUser, signOut } from "../state/app"
import { navigate, Link } from "gatsby"

// Wrap the require in check for window
import loadable from "@loadable/component"
const Feedback = loadable(() => import("feeder-react-feedback/dist/Feedback"))
import "feeder-react-feedback/dist/feeder-react-feedback.css"

import Auth from "../components/auth"
import HyperLink from "../components/hyperlink"
import Spinner from "../components/spinner"
import * as FullStory from "@fullstory/browser"

import Select from "react-select"

import "./layout.scss"
import "../pages/index.scss"

if (
  typeof window !== "undefined" &&
  !window.location.href.includes("localhost")
) {
  console.log("Fullstory initialized.")
  FullStory.init({ orgId: "V2FQC" })
}

class Layout extends Component {
  state = {
    menuOpen: false,
  }

  // Keep user logged in
  componentDidMount() {
    document.body.classList.remove("no-scroll")
    if (typeof window !== "undefined" && localStorage.hackib_token) {
      const currentTime = Date.now() / 1000
      const traits = localStorage.getItem("hackib_token").split("/")

      const expiry = parseInt(traits[0])
      const email = traits[1]

      if (expiry < currentTime) {
        console.log("Access token expired... logging user out")
        this.props.signOut()
      } else if (!this.props.isLoggedIn) {
        let user = {
          email,
          expires_at: expiry.toString(),
        }

        this.props.setUser(user)
      }
    }
  }

  handleMenuChange = path => {
    if (path.value === "logout") {
      this.props.signOut()
      return
    }
    navigate(path.value)
  }

  handleSignOut = () => {
    this.props.signOut()
  }

  // Show Side Nav
  toggleMenu = e => {
    let sideNav = document.querySelector(".nav-mobile")
    let body = document.body
    if (sideNav.classList.contains("invisible")) {
      sideNav.classList.remove("invisible")
      body.classList.add("no-scroll")
      this.setState({ menuOpen: true })
    } else {
      sideNav.classList.add("invisible")
      body.classList.remove("no-scroll")
      this.setState({ menuOpen: false })
    }
  }

  render() {
    const menuOptions = [
      { value: "", label: "Home" },
      { value: "practice", label: "Practice" },
      { value: "saved-questions", label: "Saved Questions" },
      { value: "resources", label: "Resources" },
    ]

    if (this.props.isLoggedIn) {
      menuOptions.push({ value: "logout", label: "Logout" })
    }

    const guestNav = (
      <ul className="right-nav">
        <Link
          activeClassName="active-link"
          to="/practice"
          className="underline-link"
        >
          <li>Practice</li>
        </Link>
        <Link
          activeClassName="active-link"
          to="/resources"
          className="underline-link"
        >
          <li>
            Resources
            {/* <span className="lozenge new-nav">NEW</span> */}
          </li>
        </Link>
        <Link
          activeClassName="active-link"
          to="/saved-questions"
          className="underline-link"
        >
          <li>Saved Questions</li>
        </Link>
        <Link
          activeClassName="active-link"
          to="/sign-in"
          className="underline-link"
        >
          <li>Sign In</li>
        </Link>
        <Link to="/sign-up">
          <li>
            <div className="btn">Sign Up</div>
          </li>
        </Link>
      </ul>
    )

    const authNav = (
      <ul className="right-nav">
        <Link
          activeClassName="active-link"
          to="/practice"
          className="underline-link"
        >
          <li>Practice</li>
        </Link>
        <Link
          activeClassName="active-link"
          to="/resources"
          className="underline-link"
        >
          <li>
            Resources
            {/* <span className="lozenge new-nav">NEW</span> */}
          </li>
        </Link>
        <Link
          activeClassName="active-link"
          to="/saved-questions"
          className="underline-link"
        >
          <li>Saved Questions</li>
        </Link>
        <li onClick={this.handleSignOut}>
          <div className="btn">Sign Out</div>
        </li>
      </ul>
    )

    if (this.props.loading) {
      return <Spinner />
    } else
      return (
        <>
          <div className="nav-mobile invisible">
            <div className="nav container close-container">
              <span className="close-burger" onClick={this.toggleMenu}>
                <svg width="24" height="24">
                  <rect width="24" height="24" fill="none" rx="0" ry="0" />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.4049 12.0025L18.6949 6.7125C19.0849 6.3225 19.0849 5.6925 18.6949 5.3025C18.3049 4.9125 17.6749 4.9125 17.2849 5.3025L11.9949 10.5925L6.70494 5.2925C6.31494 4.9025 5.68494 4.9025 5.29494 5.2925C4.90494 5.6825 4.90494 6.3125 5.29494 6.7025L10.5849 12.0025L5.29494 17.2925C4.90494 17.6825 4.90494 18.3125 5.29494 18.7025C5.48494 18.9025 5.73494 19.0025 5.99494 19.0025C6.25494 19.0025 6.50494 18.9025 6.70494 18.7125L11.9949 13.4125L17.2849 18.7025C17.4849 18.9025 17.7349 19.0025 17.9949 19.0025C18.2549 19.0025 18.5049 18.9025 18.7049 18.7125C19.0949 18.3225 19.0949 17.6925 18.7049 17.3025L13.4049 12.0025Z"
                    fill="#005dff"
                  />
                </svg>
              </span>
            </div>
            <Link to="/">
              <div className="logo mb-8">IB Vine</div>
            </Link>
            {this.props.isLoggedIn && (
              <ul className="burger-email">
                <li>Logged in as {this.props.user.email}</li>
              </ul>
            )}
            {this.props.isLoggedIn ? authNav : guestNav}
          </div>
          <div className="container">
            <Element name="top">
              {/* <div className="header">
              <div className="flex-nav">
                <div>
                  <div className="header-1">
                    Hack IB <span className="header-1-beta">(Beta)</span>
                  </div>
                  <div className="header-1-sub header-4 normal-case">
                    <HyperLink
                      href="https://www.bankingatmichigan.org"
                      title="by Banking at Michigan"
                    />
                  </div>
                </div>
                <div className="flex-nav-2">
                  <Select
                    blurInputOnSelect={true}
                    options={menuOptions}
                    placeholder="Menu"
                    isSearchable={false}
                    onChange={this.handleMenuChange}
                    className="react-select-container"
                    classNamePrefix="react-select"
                  />
                </div>
              </div>
              {this.props.isLoggedIn && (
                <div className="header-4 normal-case">
                  Logged in as {this.props.user.name} ({this.props.user.email})
                </div>
              )} */}
              {/* {!this.props.isLoggedIn && <Auth user={this.props.user} />} */}
              {/* </div> */}
              <div className="nav container">
                {/* LOGO */}
                <Link to="/">
                  <div className="logo">IB Vine</div>
                </Link>

                {/* HAMBURGER MENU */}
                <div className="hamburger" onClick={this.toggleMenu}>
                  <svg width="24" height="24">
                    <rect width="24" height="24" fill="none" rx="0" ry="0" />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M20 7.5H4C3.17 7.5 2.5 6.83 2.5 6C2.5 5.17 3.17 4.5 4 4.5H20C20.83 4.5 21.5 5.17 21.5 6C21.5 6.83 20.83 7.5 20 7.5ZM20 10.5H4C3.17 10.5 2.5 11.17 2.5 12C2.5 12.83 3.17 13.5 4 13.5H20C20.83 13.5 21.5 12.83 21.5 12C21.5 11.17 20.83 10.5 20 10.5ZM20 16.5H4C3.17 16.5 2.5 17.17 2.5 18C2.5 18.83 3.17 19.5 4 19.5H20C20.83 19.5 21.5 18.83 21.5 18C21.5 17.17 20.83 16.5 20 16.5Z"
                      fill="#005dff"
                    />
                  </svg>
                </div>

                {/* RIGHT NAV LINKS */}

                {this.props.isLoggedIn ? authNav : guestNav}
              </div>
              <div
                className="children"
                style={{ paddingLeft: 0, paddingRight: 0 }}
              >
                {this.props.children}
              </div>
              <div className="footer">
                {/* {"Disclaimer"} */}Created by{" "}
                <a
                  href="https://www.linkedin.com/in/rishi-pr/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Rishi Prasad
                </a>{" "}
                at{" "}
                <a
                  href="https://www.bankingatmichigan.org"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Banking at Michigan
                </a>{" "}
                ({new Date().getFullYear()})
                <div className="content disclaimer">
                  <span>
                    <Link to="/disclaimer">Disclaimer</Link> ·{" "}
                    <a
                      href="mailto:ibvine-support@umich.edu?subject=IB Vine Feedback"
                      target="_blank"
                    >
                      ibvine-support@umich.edu
                    </a>
                  </span>
                </div>
              </div>
            </Element>
          </div>
          <Feedback
            projectName="IB Vine"
            emailDefaultValue={this.props.user ? this.props.user.email : null}
            feedbackTypes={["general", "bug"]}
            projectId="5efe46757ba26a00049c29af"
            primaryColor="black"
            hoverBorderColor="black"
            submitButtonMsg="Submit"
          />
        </>
      )
  }
}

const mapStateToProps = state => {
  return {
    isLoggedIn: state.app.isLoggedIn,
    user: state.app.user,
    loading: state.app.loading,
  }
}

export default connect(mapStateToProps, { setUser, signOut })(Layout)
